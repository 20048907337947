import React, { useMemo, useCallback, useState } from 'react';
import { useDataStats } from '../../hooks/useDataStats';
import { useSortableData } from '../../hooks/useSortableData';
import { useChartLoadingStates } from '../../hooks/useChartLoadingStates';
import { formatNumber } from '../../utils/dataProcessing';
import { useTheme } from '../../auth/AuthContext';
import { graphColors } from '../../Utilities';
import { MetricCard } from '../Layouts/ChartTemplates';
import { AreaChartTemplate, PieChartTemplate, LineChartTemplate } from '../Layouts/ChartTemplates';
import LoadingSpinner from '../Layouts/LoadingSpinner';
import {
    metricsConfig,
    chartsConfig,
    displayedChartMetrics,
    transformGA4Data,
} from '../../config/GA4Config';

const GA4 = ({
    data,
    yearOverYearData,
    previousPeriodData,
    customPeriodData,
    loadingState,
    pdfRefs,
    isPDFLayout,
    isOrganic = false
}) => {
    // Track selected metrics
    const [selectedMetrics, setSelectedMetrics] = useState(['sessions']);

    if (loadingState?.isLoading) return <LoadingSpinner />;
    if (!data) return <div>No GA4 data available.</div>;
        
    const theme = useTheme();
    const lineColors = useMemo(() => graphColors(theme), [theme]);
    const pieColors = useMemo(() =>
        [lineColors.first, lineColors.second, lineColors.third, lineColors.fourth, lineColors.fifth, lineColors.sixth],
        [lineColors]
    );

    const transformedData = useMemo(() => {
        if (!data) return {};
        return transformGA4Data(data, yearOverYearData, previousPeriodData, customPeriodData);
    }, [data, yearOverYearData, previousPeriodData, customPeriodData]);

    const { sortedData: chartData } = useSortableData(transformedData.chartData || [], {
        key: 'date',
        direction: 'asc'
    });
    
    const { sortedData: newUsersData } = useSortableData(transformedData.newUsersData || [], {
        key: 'date',
        direction: 'asc'
    });

    const { sortedData: averagePagesData } = useSortableData(transformedData.averagePagesData || [], {
        key: 'date',
        direction: 'asc'
    });

    const { sortedData: sessionsPerUserData } = useSortableData(transformedData.sessionsPerUserData || [], {
        key: 'date',
        direction: 'asc'
    });
    
    const { sortedData: bounceRateData } = useSortableData(transformedData.bounceRateData || [], {
        key: 'date',
        direction: 'asc'
    });
    
    // Then combine the sorted data
    const sortedDatasets = useMemo(() => ({
        chartData: chartData || [],
        sortedData: {
            newUsers: newUsersData || [],
            averagePages: averagePagesData || [],
            sessionsPerUser: sessionsPerUserData || [],
            bounceRate: bounceRateData || []
        }
    }), [chartData, newUsersData, averagePagesData, sessionsPerUserData, bounceRateData]);

    const stats = useDataStats(
        data || {},
        yearOverYearData || {},
        previousPeriodData || {},
        customPeriodData || {},
        metricsConfig
    );

    const filteredStats = useMemo(() => {
        if (!stats) return {};
        return Object.fromEntries(
            Object.entries(stats)
                .filter(([key]) => displayedChartMetrics.includes(key))
        );
    }, [stats]);

    const loadingStates = useChartLoadingStates(
        loadingState || {},
        filteredStats,
        chartData,
        transformedData
    );

    const getLineConfig = useCallback(() => {
        const colorKeys = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'];
        
        return {
            type: 'line',
            height: 450,
            lines: selectedMetrics.map((metric, index) => {
                const metricLabel = metricsConfig[metric]?.getLabel?.(isOrganic) ?? 
                                  metricsConfig[metric]?.label ?? 
                                  metric;
                
                return {
                    dataKey: metric,
                    name: metricLabel,
                    color: lineColors[colorKeys[index % colorKeys.length]],
                    strokeWidth: 2,
                    dot: { r: 2 }
                };
            }),
        };
    }, [selectedMetrics, isOrganic, lineColors]);

    const handleMetricClick = useCallback((metricKey) => {
        setSelectedMetrics(prev => {
            const newSelected = prev.includes(metricKey)
                ? prev.filter(m => m !== metricKey)  // Remove if already selected
                : [...prev, metricKey];              // Add if not selected
            
            // Ensure at least one metric is always selected
            return newSelected.length === 0 ? [metricKey] : newSelected;
        });
    }, []);
    
     /**
     * Chart Rendering Logic
     */
     const renderChart = useCallback((chartKey, chartConfig) => {
        if (chartConfig.hideWhenOrganic && isOrganic) return null;
    
        // Get the appropriate sorted data based on chart type
        let chartData;
        if (chartConfig.type === 'area' && chartConfig.sourceKey) {
            const dataKey = chartConfig.sourceKey.replace('Data', '');
            chartData = sortedDatasets.sortedData[dataKey];
        } else {
            chartData = sortedDatasets.chartData;
        }
    
        const loadingKey = `is${chartKey.charAt(0).toUpperCase() + chartKey.slice(1)}Loading`;
        const yearOverYearChange = stats[chartKey]?.yearOverYearChange;
        const previousPeriodChange = stats[chartKey]?.previousPeriodChange;  // Add this
        const customPeriodChange = stats[chartKey]?.customPeriodChange;      // Add this
    
        switch (chartConfig.type) {
            case 'line':
                return (
                    <LineChartTemplate
                        data={chartData}
                        lines={chartConfig.lines(lineColors)}
                        xAxisDataKey="date"
                        showLegend={true}
                        height={chartConfig.height}
                        topMargin={50}
                    />
                );
            case 'area':
                return (
                    <AreaChartTemplate
                        data={chartData}
                        areas={{
                            dataKey: chartConfig.dataKey,
                            name: chartConfig.title({ isOrganic }),
                            color: lineColors.first
                        }}
                        xAxisDataKey="date"
                        title={chartConfig.title({ isOrganic })}
                        subTitle={chartConfig.getTotalValue(chartData, yearOverYearChange, previousPeriodChange, customPeriodChange)}
                        yearOverYearChange={yearOverYearChange}
                        previousPeriodChange={previousPeriodChange}
                        customPeriodChange={customPeriodChange}
                    />
                );
            case 'pie':
                return (
                    <PieChartTemplate
                        data={transformedData[chartConfig.dataKey]}
                        title={chartConfig.title({ isOrganic })}
                        colors={pieColors}
                    />
                );
            default:
                return null;
        }
    }, [sortedDatasets, stats, lineColors, isOrganic, transformedData]);

    return (
        <div id={`dashboard-${isOrganic ? 'organic' : 'traffic'}`}>
            <div className='pdf-page viewport'>
                <div className='chart-metrics-wrapper pdf-margin'>
                    <div className='chart-metrics-metrics'>
                        {loadingState?.isLoading ? (
                            <div className='h-[200px] flex justify-center items-center'>
                                <LoadingSpinner />
                            </div>
                        ) : (
                            <div className='chart-metrics-grid'>
                                {Object.entries(filteredStats || {}).map(([key, stat]) => (
                                    <div 
                                        key={key}
                                        onClick={() => handleMetricClick(key)}
                                        className="metric-card-select-wrapper"
                                    >
                                        <MetricCard
                                            label={metricsConfig[key]?.getLabel?.(isOrganic) ?? metricsConfig[key]?.label ?? key}
                                            value={stat?.value ?? 0}
                                            yearOverYearChange={stat?.yearOverYearChange ?? null}
                                            previousPeriodChange={stat?.previousPeriodChange ?? null}
                                            customPeriodChange={stat?.customPeriodChange ?? null}
                                            isSelected={selectedMetrics.includes(key)}
                                            multiSelect={true}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className='chart-metrics-chart'>
                        <LineChartTemplate
                            data={chartData}
                            lines={getLineConfig().lines}
                            xAxisDataKey="date"
                            showLegend={true}
                            height={getLineConfig().height}
                            topMargin={50}
                        />
                    </div>
                </div>
            </div>
            <div className='pdf-page viewport'>
                <div className='split-charts ga-charts'>
                    {Object.entries(chartsConfig)
                        .filter(([key, config]) => key !== 'mainTraffic' && !(config.hideWhenOrganic && isOrganic))
                        .map(([key, config]) => (
                            <div key={key} className='split-chart-third'>
                                {renderChart(key, config)}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default React.memo(GA4);
