import React, { createContext, useContext, useState } from 'react';

const YOYContext = createContext();

export const YOYProvider = ({ children }) => {
    const [showYOY, setShowYOY] = useState(false);
    const [showPrevPeriod, setShowPrevPeriod] = useState(false);
    const [showCustomPeriod, setShowCustomPeriod] = useState(false);
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    
    return (
        <YOYContext.Provider value={{
            showYOY,
            setShowYOY,
            showPrevPeriod,
            setShowPrevPeriod,
            showCustomPeriod,
            setShowCustomPeriod,
            customStartDate,
            setCustomStartDate,
            customEndDate,
            setCustomEndDate
        }}>
            {children}
        </YOYContext.Provider>
    );
};

export const useYOY = () => {
    const context = useContext(YOYContext);
    if (!context) {
        throw new Error('useYOY must be used within a YOYProvider');
    }
    return context;
};

export default YOYContext;
