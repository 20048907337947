import React, { useState, useEffect, useMemo } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Cell
} from 'recharts';

export const HorizontalBarChartTemplate = ({
    data,
    height = 400,
    currency = false,
    color = '#1f77b4'
}) => {
    const [windowWidth, setWindowWidth] = useState(
        typeof window !== 'undefined' ? window.innerWidth : 768
    );

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isMobile = windowWidth < 768;

    const margin = useMemo(() => ({
        top: 5,
        right: isMobile ? 20 : 30,
        left: isMobile ? 60 : 150,
        bottom: 5
    }), [isMobile]);

    if (!data || data.length === 0 || !data.some(item => item.value > 0)) {
        return <div style={{ width: '100%', height }}>No data available</div>;
    }

    const maxValue = Math.max(...data.map(item => item.value));
    
    const formatNumber = (value, isCurrency = false) => {
        if (isCurrency) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(value);
        }
        return new Intl.NumberFormat('en-US').format(value);
    };

    return (
        <div className="relative w-full" style={{ 
            height,
            marginLeft: isMobile ? '-1rem' : '0',
            marginRight: isMobile ? '-1rem' : '0',
            paddingLeft: isMobile ? '1rem' : '0',
            paddingRight: isMobile ? '1rem' : '0'
        }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    layout="vertical"
                    data={data}
                    margin={margin}
                >
                    <CartesianGrid 
                        strokeDasharray="3 3" 
                        horizontal={true} 
                        vertical={false}
                    />
                    <XAxis
                        type="number"
                        tickFormatter={(value) => formatNumber(value, currency)}
                        domain={[0, maxValue * 1.1]}
                        tick={{ fontSize: isMobile ? 10 : 12 }}
                    />
                    <YAxis
                        type="category"
                        dataKey="name"
                        width={isMobile ? 50 : 140}
                        tick={{ 
                            fontSize: isMobile ? 10 : 12
                        }}
                    />
                    <Tooltip
                        formatter={(value) => formatNumber(value, currency)}
                        contentStyle={{
                            fontSize: isMobile ? 12 : 14
                        }}
                    />
                    <Bar
                        dataKey="value"
                        fill={color}
                        radius={[0, 4, 4, 0]}
                        maxBarSize={40}
                    >
                        {data.map((entry, index) => (
                            <Cell 
                                key={`cell-${index}`} 
                                fill={color}
                            />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};
