import axios from "axios";

const axiosDataPipeline = axios.create({
  baseURL: import.meta.env.VITE_DATA_PIPELINE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-rm-toolkit": import.meta.env.VITE_HEADER_VALUE,
  },
});

// Request interceptor
axiosDataPipeline.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosDataPipeline.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && (error.response.status === 404 || error.response.status === 500)) {
      // Return a resolved promise with null data for 404 and 500 errors
      return Promise.resolve({ 
        data: null, 
        status: error.response.status,
        statusText: error.response.statusText,
        headers: error.response.headers,
        config: error.config
      });
    }
    return Promise.reject(error);
  }
);

export default axiosDataPipeline;
