/**
 * Sorts an array of objects based on a specified column and direction.
 * 
 * @param {Array} data - The array of objects to sort
 * @param {string} sortColumn - The key of the object to sort by
 * @param {string} sortDirection - The direction to sort ('asc' for ascending, 'desc' for descending)
 * @returns {Array} A new sorted array, leaving the original array unmodified
 */
export const sortData = (data, sortColumn, sortDirection) => {
    return [...data].sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
        return 0;
    });
};
