import { useState, useMemo } from 'react';
import { sortData } from '../utils/sorting';

export const useSortableData = (data, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedData = useMemo(() => {
        if (!sortConfig) return data;
        return sortData(data, sortConfig.key, sortConfig.direction);
    }, [data, sortConfig]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    return { sortedData, requestSort, sortConfig };
};
