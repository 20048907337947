import {
    formatNumber, 
} from './sharedImports';

export const CustomTooltip = ({ active, payload, totalValue }) => {
    if (active && payload && payload.length) {
        const data = payload[0];
        const percentage = ((data.value / totalValue) * 100).toFixed(2);

        return (
            <div className='custom-tooltip' style={{
                backgroundColor: 'black',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '5px'
            }}>
                <p>{`${data.name}: ${formatNumber(data.value)} - ${percentage}%`}</p>
            </div>
        );
    }

    return null;
};
