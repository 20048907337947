/**
 * Get the subdomain app is running on.
 * Used to determine the theme to show (mta360 or Range)
 *
 */
export const getReportingTheme = () => {
    const hostname = window.location.hostname;
    
    if (hostname.includes('reporting-dev.rangemarketing')) {
        return 'range';
    }
    
    if (hostname.includes('range')) {
        return 'range';
    }
    
    if (hostname.includes('mta360')) {
        return 'mta';
    }
    
    return 'range';
};

/**
 * Swap graph colors based on theme.
 */
export const graphColors = (theme) => {
    let colors;
    
    if (theme === 'mta') {
        colors = {
            'first': '#26bd78',
            'second': '#fcb70b',
            'third': '#005495',
            'fourth': '#eb2c34',
            'fifth': '#6273FF',
            'sixth': '#8B5CF6',
        }
    } else {
        colors = {
            'first': '#26bd78',
            'second': '#fcb70b',
            'third': '#005495',
            'fourth': '#eb2c34',
            'fifth': '#6273FF',
            'sixth': '#8B5CF6',
        } 
    }
    
    return colors;
};

/**
 * Apply classnames to Tailwind elements.
 */

export const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
};

export const getDatesInRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    const end = new Date(endDate);

    while (currentDate <= end) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
};
