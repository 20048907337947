import {
    useYOY,
    formatNumber,
    shouldShowPercentChange
} from './sharedImports';

export const HighlightCard = ({
    title,
    value,
    yearOverYearChange,
    previousPeriodChange,
    customPeriodChange,  // Added
    previousValue,
    Icon,
    cardClass,
    color,
    totalStatcard
}) => {
    const { showYOY, showPrevPeriod, showCustomPeriod } = useYOY();  // Added showCustomPeriod

    const renderChangeIndicator = (change, label) => {
        if (!shouldShowPercentChange(change)) {
            return null;
        }
        
        return (
            <div className={`metric-card-change ${change >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                {label}: {change >= 0 ? '▲' : '▼'} {Math.abs(change).toFixed(2)}%
            </div>
        );
    };

    let isTotal, cardStyle, metricStyle, labelColor;

    if (totalStatcard) {
        isTotal = 'highlight-total';
        cardStyle = {
            borderColor: 'transparent',
            backgroundColor: color,
        };
        metricStyle = { color: 'white' };
        labelColor = '!text-white';
    } else {
        isTotal = null;
        cardStyle = {
            borderColor: 'rgb(209 213 219)',
        };
        metricStyle = { color: color };
        labelColor = '';
    }

    return (
        <div className={`rr-statcard-card ${cardClass} ${isTotal}`} style={cardStyle}>
            <div className='statcard-card-data'>
                <div className='statcard-card-default'>
                    <h3 style={metricStyle}>{title}</h3>
                    <div className='metric-card-valuechange'>
                        <div className='statcard-metric' style={metricStyle}>
                            {formatNumber(value)}
                        </div>
                        <div className="flex flex-col">
                            {showYOY && yearOverYearChange !== undefined && yearOverYearChange !== null &&
                                renderChangeIndicator(yearOverYearChange, 'YOY')}
                            {showPrevPeriod && previousPeriodChange !== undefined && previousPeriodChange !== null &&
                                renderChangeIndicator(previousPeriodChange, 'Previous')}
                            {showCustomPeriod && customPeriodChange !== undefined && customPeriodChange !== null &&
                                renderChangeIndicator(customPeriodChange, 'Custom')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
