import {
    useYOY,
    formatNumber,
    shouldShowPercentChange
} from './sharedImports';

export const MetricCard = ({
    label,
    value,
    yearOverYearChange,
    previousPeriodChange,
    customPeriodChange,
    isCallTracking = false,
    cardClass,
    currency = false,
    isSelected,
    multiSelect = false 
}) => {
    const { showYOY, showPrevPeriod, showCustomPeriod } = useYOY();
    const displayValue = isCallTracking ? value : formatNumber(value, currency);
    
    const selectedStyles = multiSelect
        ? '!bg-blue-50'
        : null;

    const renderChangeIndicator = (change, label) => {
        if (!shouldShowPercentChange(change)) {
            return null;
        }
        
        return (
            <div className={`metric-card-change ${change >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                {label}: {change >= 0 ? '▲' : '▼'} {Math.abs(change).toFixed(2)}%
            </div>
        );
    };

    return (
        <div className={`metric-card ${cardClass || ''} ${isSelected ? selectedStyles : 'border-gray-200 hover:border-blue-300'}
                ${multiSelect ? 'hover:bg-blue-50/50' : ''}`}>
            <h3 className='metric-card-label'>{label}</h3>
            {multiSelect && (
                    <div className={`
                        metric-card-multiselect
                        ${isSelected 
                            ? 'multiselect-active' 
                            : null}
                    `}>
                        {isSelected && (
                            <svg 
                                viewBox="0 0 24 24" 
                                className="multi-select-svg"
                                fill="none" 
                                stroke="currentColor" 
                                strokeWidth="2"
                            >
                                <path 
                                    d="M5 13l4 4L19 7" 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round"
                                />
                            </svg>
                        )}
                    </div>
                )}
            <div className='metric-card-valuechange'>
                <div className='metric-card-value'>{displayValue}</div>
                <div className="flex flex-col gap-1">
                    {showYOY && renderChangeIndicator(yearOverYearChange, 'YOY')}
                    {showPrevPeriod && renderChangeIndicator(previousPeriodChange, 'Previous')}
                    {showCustomPeriod && renderChangeIndicator(customPeriodChange, 'Custom')}
                </div>
            </div>
        </div>
    );
};
