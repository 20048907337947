import {
    React, 
    useMemo,
    useCallback,
    useYOY, 
    formatNumber, 
    shouldShowPercentChange
} from './sharedImports';

export const ComparisonTable = ({
    data,
    yearOverYearData,
    previousPeriodData,
    type = 'products',
    currency = true
}) => {
    const { showYOY, showPrevPeriod } = useYOY();

    const getComparisons = useCallback((currentItem, metric = 'revenue') => {
        let yearOverYearItem, previousPeriodItem;
        
        if (type === 'products') {
            yearOverYearItem = yearOverYearData?.find(item => item.name === currentItem.name);
            previousPeriodItem = previousPeriodData?.find(item => item.name === currentItem.name);
        } else {
            yearOverYearItem = yearOverYearData?.find(item => item.source === currentItem.source);
            previousPeriodItem = previousPeriodData?.find(item => item.source === currentItem.source);
        }
    
        const yearOverYearValue = yearOverYearItem?.[metric];
        const previousPeriodValue = previousPeriodItem?.[metric];
        const currentValue = currentItem[metric];
    
        return {
            yearOverYearChange: yearOverYearValue != null && currentValue != null
                ? ((currentValue - yearOverYearValue) / yearOverYearValue) * 100
                : null,
            previousPeriodChange: previousPeriodValue != null && currentValue != null 
                ? ((currentValue - previousPeriodValue) / previousPeriodValue) * 100
                : null
        };
    }, [yearOverYearData, previousPeriodData, type]);

    const renderChangeCell = useCallback((change) => {
        if (change === null) return <td className="td-metric">-</td>;
        return (
            <td className={`td-metric whitespace-nowrap ${change >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                {change >= 0 ? '▲' : '▼'} {Math.abs(change).toFixed(1)}%
            </td>
        );
    }, []);

    const hasYOYChanges = useMemo(() => {
        return data.some(item => {
            const comparisons = getComparisons(item);
            return shouldShowPercentChange(comparisons.yearOverYearChange);
        });
    }, [data, getComparisons]);

    const hasPrevPeriodChanges = useMemo(() => {
        return data.some(item => {
            const comparisons = getComparisons(item);
            return shouldShowPercentChange(comparisons.previousPeriodChange);
        });
    }, [data, getComparisons]);

    const tableRows = useMemo(() => {
        return data.map((item, index) => {
            if (type === 'products') {
                const quantityComparisons = getComparisons(item, 'quantity');
                const revenueComparisons = getComparisons(item, 'revenue');
                
                return (
                    <tr key={index} className="data-tr">
                        <td className="td-index">{index + 1}</td>
                        <td className="td-primary lg:min-w-[120px]">{item.name}</td>
                        <td className="td-primary lg:min-w-[100px]">{item.brand}</td>
                        <td className="td-metric whitespace-nowrap">{formatNumber(item.quantity)}</td>
                        {showYOY && shouldShowPercentChange(quantityComparisons.yearOverYearChange) && renderChangeCell(quantityComparisons.yearOverYearChange)}
                        <td className="td-metric font-bold whitespace-nowrap">
                            {formatNumber(item.revenue, currency)}
                        </td>
                        {showYOY && shouldShowPercentChange(revenueComparisons.yearOverYearChange) && renderChangeCell(revenueComparisons.yearOverYearChange)}
                        {showPrevPeriod && shouldShowPercentChange(revenueComparisons.previousPeriodChange) && renderChangeCell(revenueComparisons.previousPeriodChange)}
                    </tr>
                );
            }
            
            const comparisons = getComparisons(item);
            return (
                <tr key={index} className="data-tr">
                    <td className="td-primary lg:min-w-[120px]">{item.source}</td>
                    <td className="td-metric font-bold whitespace-nowrap">
                        {formatNumber(item.revenue, currency)}
                    </td>
                    {showYOY && shouldShowPercentChange(comparisons.yearOverYearChange) && renderChangeCell(comparisons.yearOverYearChange)}
                    {showPrevPeriod && shouldShowPercentChange(comparisons.previousPeriodChange) && renderChangeCell(comparisons.previousPeriodChange)}
                </tr>
            );
        });
    }, [data, type, showYOY, showPrevPeriod, getComparisons, renderChangeCell, currency]);

    const TableWrapper = ({ children }) => (
        <div className="relative w-full">
            <div className="overflow-x-auto overflow-y-hidden">
                <div className="inline-block min-w-full align-middle">
                    {children}
                </div>
            </div>
        </div>
    );

    if (type === 'products') {
        return (
            <TableWrapper>
                <table className="data-table">
                    <thead>
                        <tr>
                            <th className="thead-index sticky left-0 z-10"></th>
                            <th className="thead-primary lg:min-w-[120px]">Product</th>
                            <th className="thead-primary lg:min-w-[100px]">Brand</th>
                            <th className="thead-metric whitespace-nowrap">Items Sold</th>
                            {showYOY && hasYOYChanges && <th className="thead-metric whitespace-nowrap">YoY Change</th>}
                            <th className="thead-metric whitespace-nowrap">Revenue</th>
                            {showYOY && hasYOYChanges && <th className="thead-metric whitespace-nowrap">YoY Change</th>}
                            {showPrevPeriod && hasPrevPeriodChanges && <th className="thead-metric whitespace-nowrap">Prev Change</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            </TableWrapper>
        );
    }

    return (
        <TableWrapper>
            <table className="data-table">
                <thead>
                    <tr>
                        <th className="thead-primary lg:min-w-[120px]">Traffic Source</th>
                        <th className="thead-metric whitespace-nowrap">Revenue</th>
                        {showYOY && hasYOYChanges && <th className="thead-metric whitespace-nowrap">YoY Change</th>}
                        {showPrevPeriod && hasPrevPeriodChanges && <th className="thead-metric whitespace-nowrap">Prev Change</th>}
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        </TableWrapper>
    );
};
