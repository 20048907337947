import {
    useMemo,
    PieChart,
    Pie,
    Cell,
    Tooltip, 
    Legend, 
    ResponsiveContainer,
    useWindowSize,
    capitalizeWords,
} from './sharedImports';

import { CustomTooltip } from './CustomTooltip';

export const PieChartTemplate = ({
    data,
    colors,
    title,
    outerRadius = '80%'
}) => {
    const { width } = useWindowSize();
    const isMobile = width < 768;

    const chartSize = useMemo(() => {
        if (isMobile) {
            return Math.min(width * 0.8, 300);
        }
        return 300;
    }, [width, isMobile]);

    const totalValue = useMemo(() => data.reduce((sum, item) => sum + item.value, 0), [data]);

    const legendStyle = {
        fontSize: isMobile ? '10px' : '12px',
        lineHeight: '1.5',
        fontWeight: 'bold',
    };

    return (
        <div className='rr-pie-chart' style={{
            width: '100%',
            height: isMobile ? `${chartSize + 100}px` : '400px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            {title && <h2 className='mb-4 text-center'>{title}</h2>}
            <div style={{ width: '100%', height: isMobile ? `${chartSize}px` : '300px' }}>
                <ResponsiveContainer width='100%' height='100%'>
                    <PieChart>
                        <Pie
                            data={data}
                            cx='50%'
                            cy='50%'
                            labelLine={false}
                            label={false}
                            outerRadius={isMobile ? '90%' : outerRadius}
                            fill='#8884d8'
                            dataKey='value'
                        >
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={colors[entry.name] || Object.values(colors)[index % Object.values(colors).length]}
                                />
                            ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip totalValue={totalValue} />} />
                        <Legend
                            layout={isMobile ? 'horizontal' : 'vertical'}
                            align={isMobile ? 'center' : 'right'}
                            verticalAlign={isMobile ? 'bottom' : 'middle'}
                            iconType='circle'
                            formatter={(value) => capitalizeWords(value)}
                            wrapperStyle={{
                                paddingTop: isMobile ? '10px' : '0',
                                ...legendStyle
                            }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};
