import {
    useMemo,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    useWindowSize,
    calculateDomainAndTicks,
    getCustomXAxisTicks,
    formatNumber,
    AreaChart,
    Area,
    defaultLegendStyle,
    shouldShowPercentChange,
    useYOY,
    tickFormatter,
    tooltipLabelFormatter,
    createTooltipFormatter,
    normalizeChartDate,
    getTickFormatter
} from './sharedImports';

export const AreaChartTemplate = ({
    data,
    areas,
    xAxisDataKey = 'date',
    height = 300,
    showLegend = false,
    legendStyle = defaultLegendStyle,
    title,
    subTitle,
    topMargin = 0,
    currency = false,
    yearOverYearChange,
    previousPeriodChange,
    customPeriodChange
}) => {
    const { width } = useWindowSize();
    const isMobile = width < 768;
    const { showYOY, showPrevPeriod, showCustomPeriod } = useYOY();

    // Keep normalized data for calculations only
    // const normalizedData = useMemo(() => {
    //     return data.map(item => ({
    //         ...item,
    //         [xAxisDataKey]: normalizeChartDate(item[xAxisDataKey])
    //     }));
    // }, [data, xAxisDataKey]);

    const renderChangeIndicator = (change, label) => {
        if (!shouldShowPercentChange(change)) return null;

        return (
            <span className={`${change >= 0 ? 'text-green-500' : 'text-red-500'} ml-2`}>
                {label}: {change >= 0 ? '▲' : '▼'} {Math.abs(change).toFixed(2)}%
            </span>
        );
    };

    const chartHeight = useMemo(() => {
        if (isMobile) {
            return width * 0.75;
        }
        return height;
    }, [width, height, isMobile]);

    const areaArray = Array.isArray(areas) ? areas : [areas];

    const { formatter, ticks } = useMemo(() => 
        getTickFormatter(data, xAxisDataKey),
        [data, xAxisDataKey]
    );
    
    const { yDomain, yTicks, useDecimals } = useMemo(() =>
        calculateDomainAndTicks(data, areaArray.map(area => area.dataKey)),
        [data, areaArray]
    );

    const margin = useMemo(() => {
        if (isMobile) {
            return { top: 5, right: 5, left: 5, bottom: 20 };
        }
        return { top: topMargin, right: 30, left: 20, bottom: 50 };
    }, [isMobile, topMargin]);

    // Use shared tooltip formatter
    const tooltipFormatter = useMemo(() => createTooltipFormatter(currency), [currency]);

    // Format values with appropriate decimal places based on magnitude
    const formatValue = (value) => {
        if (useDecimals) {
            return Number(value).toFixed(2);
        }
        return formatNumber(value, currency);
    };

    return (
        <div className="w-full h-auto">
            {title && <h2 className="mb-4 text-center">{title}</h2>}
            {subTitle && (
                <h2 className="mb-4 lg:mb-6 text-center area-chart-subtitle flex flex-col items-center justify-center gap-2">
                    <span>{subTitle.total}</span>
                    <div className="flex flex-col text-sm items-center">
                        {showYOY && renderChangeIndicator(yearOverYearChange, 'YOY')}
                        {showPrevPeriod && renderChangeIndicator(previousPeriodChange, 'Previous')}
                        {showCustomPeriod && renderChangeIndicator(customPeriodChange, 'Custom')}
                    </div>
                </h2>
            )}
            <div style={{ width: '100%', height: `${chartHeight}px`, position: 'relative' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={data}>
                        <defs>
                            {areaArray.map((area, index) => (
                                <linearGradient key={`gradient-${index}`} id={`gradient-${area.dataKey}`} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor={area.color} stopOpacity={1} />
                                    <stop offset="100%" stopColor={area.color} stopOpacity={0.3} />
                                </linearGradient>
                            ))}
                        </defs>
                        <XAxis
                            dataKey={xAxisDataKey}
                            tickFormatter={formatter}
                            ticks={ticks}
                            angle={-45}
                            textAnchor='end'
                            height={isMobile ? 50 : 70}
                            tick={{ fontSize: isMobile ? 10 : 12, fontWeight: 'bold' }}
                            axisLine={false}
                            tickLine={false}
                            type="category" 
                        />
                        <YAxis
                            domain={yDomain}
                            ticks={yTicks}
                            axisLine={false}
                            tickLine={false}
                            tick={{ fontSize: isMobile ? 10 : 12 }}
                            width={isMobile ? 30 : 60}
                            tickFormatter={formatValue}
                        />
                        <Tooltip
                            // labelFormatter={tooltipLabelFormatter}
                            formatter={tooltipFormatter}
                        />
                        {showLegend && <Legend {...legendStyle} />}
                        {areaArray.map((area, index) => (
                            <Area
                                key={index}
                                type="monotone"
                                dataKey={area.dataKey}
                                name={area.name}
                                stroke={area.color}
                                fill={`url(#gradient-${area.dataKey})`}
                                strokeWidth={3}
                            />
                        ))}
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default AreaChartTemplate;
