import React, { useState, useEffect } from 'react';
import { useDataStats } from '../../hooks/useDataStats';
import { useSortableData } from '../../hooks/useSortableData';
import { formatNumber } from '../../utils/dataProcessing';
import { useTheme } from '../../auth/AuthContext';
import LoadingSpinner from '../Layouts/LoadingSpinner';
import { MetricCard } from '../Layouts/ChartTemplates';
import { metricsConfig, tableColumns } from '../../config/GSCConfig';

const GSC = ({
    data,
    yearOverYearData,
    previousPeriodData,
    customPeriodData,
    loadingState,
    pdfRefs,
    isPDFLayout,
    siteUrl
}) => {
    if (loadingState) {
        return <LoadingSpinner />;
    }
    const theme = useTheme();
    const [hasData, setHasData] = useState(false);
    const [topKeywords, setTopKeywords] = useState({ impressions: [], clicks: [] });

    useEffect(() => {
        // Check for data in the keywords property for keyword-related features
        if (data?.keywords?.rows && Array.isArray(data.keywords.rows) && data.keywords.rows.length > 0) {
            setHasData(true);
            const impressionsSort = [...data.keywords.rows].sort((a, b) => b.impressions - a.impressions);
            const clicksSort = [...data.keywords.rows].sort((a, b) => b.clicks - a.clicks);

            setTopKeywords({
                impressions: impressionsSort.slice(0, 10),
                clicks: clicksSort.slice(0, 10)
            });
        } else {
            setHasData(false);
        }
    }, [data]);

    const getRef = (section) => {
        if (isPDFLayout) {
            if (!pdfRefs || Object.keys(pdfRefs).length === 0) return null;
            const key = Object.keys(pdfRefs)[0];
            const ref = pdfRefs[key] && pdfRefs[key].ref;
            return ref ? el => { ref.current = el; } : null;
        }
        return section ? pdfRefs && pdfRefs[section] && pdfRefs[section].ref : null;
    };

    // Update stats to use the main data (with date dimension) for metrics
    const stats = useDataStats(
        { ...data, rows: data?.rows || [] },
        { ...yearOverYearData, rows: yearOverYearData?.rows || [] },
        { ...previousPeriodData, rows: previousPeriodData?.rows || [] },
        { ...customPeriodData, rows: customPeriodData?.rows || [] }, // Added
        metricsConfig
    );

    // Use keywords data for the sortable table
    const { sortedData: sortedTableData, requestSort, sortConfig } = useSortableData(
        data?.keywords?.rows || [],
        {
            key: 'impressions',
            direction: 'desc'
        }
    );

    // Check both main data and keywords data for availability
    if (!hasData || !data?.rows?.length) {
        return <div>No Search Console data available.</div>;
    }

    return (
        <div>
            <div className='pdf-page viewport' ref={getRef('overview')} data-pdf-title='Search Console Overview' data-pdf-scale='0.98' id='dashboard-gsc'>
                <div className='gsc-overview'>
                    <div className='gsc-metrics'>
                        {Object.entries(stats).map(([key, stat]) => {
                            return (
                                <MetricCard
                                    key={key}
                                    label={metricsConfig[key].label}
                                    value={stat.value}
                                    yearOverYearChange={stat.yearOverYearChange}
                                    previousPeriodChange={stat.previousPeriodChange}
                                    customPeriodChange={stat.customPeriodChange} // Added
                                    cardClass='metric-card-lg'
                                />
                            );
                        })}
                    </div>
                    <div className='gsc-top'>
                        <div className='gsc-top-box'>
                            <div className='gsc-top-box-title'>Top Search Queries</div>
                            <div className='gsc-top-box-data'>
                                <div className='gsc-top-data-header'>
                                    <span className='gsc-header-kw'>Keyword</span>
                                    <span className='gsc-header-result'>Impressions</span>
                                </div>
                                {topKeywords.impressions.map((keyword, index) => (
                                    <div key={index} className={`gsc-top-data-line ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                        <span className='top-data-line-number'>{index + 1}.</span>
                                        <span className='gsc-top-kw'>{keyword.keys[0]}</span>
                                        <span className='gsc-top-result'>{formatNumber(keyword.impressions)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gsc-keywords'>
                    <h2 className='gsc-keywords-title'>
                        Highest Ranking Terms for {siteUrl && (
                            <span className='font-semibold'>{siteUrl}</span>
                        )}
                    </h2>
                    <div className='gsc-kw-table'>
                        <div className='flex flex-wrap gap-1'>
                            {(sortedTableData || []).slice(0, 50).map((row, index) => (
                                <div
                                    key={index}
                                    className={`inline-flex px-4 py-2 rounded ${index % 2 === 0 ? 'bg-gray-100' : ''}`}
                                >
                                    {row.keys?.[0] || ''}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GSC;
