export const metricsConfig = {
    impressions: {
        calculate: (data) => {
            try {
                if (!data?.rows) return 0;
                return data.rows.reduce((total, row) => {
                    const value = parseInt(row.impressions) || 0;
                    return total + value;
                }, 0);
            } catch (error) {
                console.error('Error calculating impressions:', error);
                return 0;
            }
        },
        label: 'Impressions - Google Search'
        // Removed format function since MetricCard handles it
    },
    clicks: {
        calculate: (data) => {
            try {
                if (!data?.rows) return 0;
                return data.rows.reduce((total, row) => {
                    const value = parseInt(row.clicks) || 0;
                    return total + value;
                }, 0);
            } catch (error) {
                console.error('Error calculating clicks:', error);
                return 0;
            }
        },
        label: 'Clicks - Google Search'
        // Removed format function since MetricCard handles it
    }
};

export const tableColumns = [
    { key: 'keys', label: 'Most Common Search Queries' },
    // { key: 'clicks', label: 'Clicks', format: formatNumber },
    // { key: 'impressions', label: 'Impressions', format: formatNumber },
    // { key: 'ctr', label: 'CTR', format: formatPercentage },
];
