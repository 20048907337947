import { prettyDate, formatNumber, prettyDateSmall, formatDate } from './dataProcessing';

/**
 * Calculates the y-axis domain and tick values for a chart based on data values.
 * Handles both small ranges (0-3) and larger numbers differently.
 * 
 * @param {Array<Object>} data - Array of data points to be displayed
 * @param {Array<string>} dataKeys - Array of object keys containing the values to plot
 * @returns {Object} Configuration object containing:
 *   - yDomain: [min, max] array for y-axis boundaries
 *   - yTicks: Array of tick values to display
 *   - useDecimals: Boolean indicating if decimal places should be shown
 */
export const calculateDomainAndTicks = (data, dataKeys) => {
    if (!data?.length) return { yDomain: [0, 0], yTicks: [] };

    const values = data.flatMap(item =>
        dataKeys.map(key => item[key])
    ).filter(Boolean);

    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    const range = maxValue - minValue;

    // Special handling for small ranges (e.g., 1-3)
    if (maxValue <= 3 && minValue >= 0 && range <= 3) {
        const padding = range * 0.1;
        // Add 50% padding below minimum
        const minPadding = minValue * 0.5;
        const domainMin = Math.max(0, minValue - minPadding);
        const domainMax = maxValue + padding;

        const tickInterval = 0.2;
        const tickCount = Math.ceil((domainMax - domainMin) / tickInterval) + 1;
        const ticks = Array.from(
            { length: tickCount },
            (_, i) => Number((domainMin + (i * tickInterval)).toFixed(2))
        )
        .filter(tick => tick <= domainMax)
        .map((tick, i) => Number((tick + i * 0.000001).toFixed(6)));

        return {
            yDomain: [domainMin, domainMax],
            yTicks: ticks,
            useDecimals: true
        };
    }

    // Standard handling for larger numbers
    const topPadding = range * 0.1;
    // Add 50% padding below minimum
    const bottomPadding = minValue * 0.35;
    const domainMin = Math.floor(Math.max(0, minValue - bottomPadding));
    const domainMax = Math.ceil(maxValue + topPadding);

    const tickCount = 5;
    const tickInterval = Math.ceil((domainMax - domainMin) / (tickCount - 1));
    const ticks = Array.from(
        { length: tickCount },
        (_, i) => domainMin + (i * tickInterval)
    )
    .map((tick, i) => tick + i * 0.000001);

    return {
        yDomain: [domainMin, domainMax],
        yTicks: ticks,
        useDecimals: false
    };
};


/**
 * Generates custom x-axis tick values based on date range.
 * Adapts tick frequency based on the total time span:
 * - Daily ticks for ranges <= 31 days
 * - Weekly ticks for ranges <= 183 days
 * - Monthly ticks for ranges > 183 days
 * 
 * @param {Array<Object>} data - Array of data points
 * @param {string} xAxisDataKey - Key for x-axis values in data objects
 * @returns {Array<Date>} Array of dates to use as x-axis ticks
 */
export const getCustomXAxisTicks = (data, xAxisDataKey) => {
    if (!data || data.length === 0) return [];

    const dates = data
        .map(item => new Date(item[xAxisDataKey]))
        .filter(date => !isNaN(date.getTime()))
        .sort((a, b) => a - b);

    if (dates.length === 0) return [];

    const startDate = dates[0];
    const endDate = dates[dates.length - 1];
    const daysDiff = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

    if (daysDiff > 31) {
        const interval = daysDiff > 183 ? 30 : 7;
        const ticks = [startDate];
        
        let currentDate = new Date(startDate);
        while (currentDate < endDate) {
            currentDate = new Date(currentDate);
            currentDate.setDate(currentDate.getDate() + interval);
            
            if (currentDate <= endDate) {
                const closestDate = dates.reduce((prev, curr) => {
                    return Math.abs(curr - currentDate) < Math.abs(prev - currentDate) ? curr : prev;
                });
                if (!ticks.includes(closestDate)) {
                    ticks.push(closestDate);
                }
            }
        }
        
        if (!ticks.includes(endDate)) {
            ticks.push(endDate);
        }
        
        return dates;
    }

    // For shorter ranges, show every third day
    return dates.filter((_, index) => index % 3 === 0);
};

/**
 * Generates a formatted date range label based on aggregation type.
 * Supports daily, weekly, and monthly formatting.
 * 
 * @param {string} dateStr - Date string to format
 * @param {string} aggregationType - Type of date aggregation ('week', 'month', or 'day')
 * @returns {string} Formatted date range label
 */
export const getDateRangeLabel = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
        console.warn('Invalid date in getDateRangeLabel:', dateStr);
        return '';
    }
    return prettyDate(date);
};

/**
 * Default styling configuration for chart legends.
 * Provides consistent styling across all charts.
 */
export const defaultLegendStyle = {
    verticalAlign: 'top',
    height: 60,
    wrapperStyle: {
        paddingBottom: '20px',
        fontSize: '24px',
        fontWeight: 'bold'
    },
    iconType: 'circle'
};

/**
 * Determines whether to display a percentage change value.
 * Filters out invalid or extreme values that might distort the visualization.
 * 
 * @param {number} percentChange - The percentage change to evaluate
 * @returns {boolean} Whether the percentage change should be displayed
 */
export const shouldShowPercentChange = (percentChange) => {
    // Return false if not a valid number
    if (typeof percentChange !== 'number' || isNaN(percentChange)) {
        return false;
    }
    
    // Don't show if the percent change is exactly 0 or 100
    if (percentChange === 0 || percentChange === 100) return false;
    
    // Don't show if the percent change is greater than 2000
    if (Math.abs(percentChange) > 2000) return false;
    
    return true;
};

// Date handling utilities using US Eastern timezone
const EASTERN_TZ = 'America/New_York';
const DATE_OPTIONS = { timeZone: EASTERN_TZ, year: 'numeric', month: '2-digit', day: '2-digit' };

export const normalizeChartDate = (date) => {
    if (!date) return null;
    // Create date object and set to midnight Eastern time
    const d = new Date(date);
    const easternDate = new Date(d.toLocaleString('en-US', { timeZone: EASTERN_TZ }));
    easternDate.setHours(0, 0, 0, 0);
    return easternDate;
};

export const formatChartDate = (dateStr) => {
    const date = new Date(dateStr);
    // Ensure we're working with local timezone midnight
    date.setHours(0, 0, 0, 0);
    return date;
};

export const tickFormatter = (dateStr) => {
    const formatted = prettyDateSmall(dateStr);
    return formatted;
};

export const tooltipLabelFormatter = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString('en-US', {
        timeZone: EASTERN_TZ,
        month: 'numeric', 
        day: 'numeric',
        year: 'numeric'
    });
};

export const tooltipFormatter = (value, name) => {
    if (name.includes('(Previous Year)')) {
        return [`${formatNumber(value, currency)} (Previous Year)`, name.replace(' (Previous Year)', '')];
    }
    if (name.includes('(Previous Period)')) {
        return [`${formatNumber(value, currency)} (Previous Period)`, name.replace(' (Previous Period)', '')];
    }
    return [formatNumber(value, currency), name];
};

export const createTooltipFormatter = (currency) => (value, name) => {
    if (name.includes('(Previous Year)')) {
        return [`${formatNumber(value, currency)} (Previous Year)`, name.replace(' (Previous Year)', '')];
    }
    if (name.includes('(Previous Period)')) {
        return [`${formatNumber(value, currency)} (Previous Period)`, name.replace(' (Previous Period)', '')];
    }
    return [formatNumber(value, currency), name];
};

export const getTickFormatter = (data, xAxisDataKey) => {
    return {
        formatter: (dateStr) => {
            if (!dateStr || typeof dateStr !== 'string') {
                return '';
            }
            
            // Validate format is YYYY-MM-DD
            if (!/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
                return dateStr; // Return original if invalid format
            }
 
            try {
                const [_, month, day] = dateStr.split('-');
                const parsedMonth = parseInt(month);
                const parsedDay = parseInt(day);
                
                // Validate month and day are valid numbers
                if (isNaN(parsedMonth) || isNaN(parsedDay)) {
                    return dateStr;
                }
                
                return `${parsedMonth}/${parsedDay}`;
            } catch (err) {
                return dateStr; // Return original on any error
            }
        },
        ticks: (() => {
            // Validate inputs
            if (!Array.isArray(data) || !data.length || !xAxisDataKey) {
                return [];
            }
 
            // Filter out invalid dates
            const allDates = data
                .map(item => item?.[xAxisDataKey])
                .filter(date => date && typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date));
 
            const totalDays = allDates.length;
            if (totalDays === 0) return [];
            if (totalDays <= 10) return allDates;
            
            const interval = Math.floor(totalDays / 7);
            const middleTicks = allDates
                .filter((_, i) => i % interval === 0)
                .slice(1, -1);
 
            // Ensure first and last dates exist
            return [
                allDates[0],
                ...middleTicks,
                allDates[totalDays - 1]
            ].filter(Boolean);
        })()
    };
 };
