import {
    React,
    useMemo,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    useYOY,
    useWindowSize,
    calculateDomainAndTicks,
    getCustomXAxisTicks,
    getDateRangeLabel,
    formatNumber,
    tickFormatter,
    tooltipFormatter,
    tooltipLabelFormatter,
    createTooltipFormatter,
    formatDate,
    normalizeChartDate,
    getTickFormatter
} from './sharedImports';

export const LineChartTemplate = ({
    data,
    lines,
    xAxisDataKey,
    height = 400,
    showLegend = false,
    legendStyle = {
        verticalAlign: 'top',
        height: 60,
        wrapperStyle: {
            paddingBottom: '20px',
            fontSize: '24px',
            fontWeight: 'bold'
        },
    },
    topMargin = 50,
    currency = false,
}) => {
    const { width } = useWindowSize();
    const isMobile = width < 768;
    const marginTop = typeof topMargin === 'number' ? `${topMargin}px` : topMargin;

    const { formatter, ticks } = useMemo(() => 
        getTickFormatter(data, xAxisDataKey),
        [data, xAxisDataKey]
    );
    
    const { yDomain, yTicks } = useMemo(() => {
        const dataKeys = lines.map(line => line.dataKey);
        return calculateDomainAndTicks(data, dataKeys);
    }, [data, lines]);

    const chartHeight = useMemo(() => {
        if (isMobile) {
            return width * 0.75;
        }
        return height;
    }, [width, height, isMobile]);

    const margin = useMemo(() => {
        if (isMobile) {
            return { top: 5, right: 5, left: 5, bottom: 20 };
        }
        return { top: 5, right: 30, left: 20, bottom: 50 };
    }, [isMobile]);

    const tooltipFormatter = useMemo(() => createTooltipFormatter(currency), [currency]);

    return (
        <div className='rr-linechart w-full' style={{ height: `${chartHeight}px`, marginTop }}>
            <ResponsiveContainer width='100%' height='100%' minWidth={0}>
                <LineChart data={data} margin={margin}>
                    <CartesianGrid strokeDasharray='3 3' horizontal={true} vertical={false} />
                    <XAxis
                        dataKey={xAxisDataKey}
                        tickFormatter={formatter}
                        ticks={ticks}
                        angle={-45}
                        textAnchor='end'
                        height={isMobile ? 50 : 70}
                        tick={{ fontSize: isMobile ? 10 : 12, fontWeight: 'bold' }}
                        axisLine={false}
                        tickLine={false}
                        type="category" 
                    />
                    <YAxis
                        domain={yDomain}
                        ticks={yTicks}
                        axisLine={false}
                        tickLine={false}
                        tick={{ fontSize: isMobile ? 10 : 12 }}
                        width={isMobile ? 30 : 60}
                        tickFormatter={(value) => formatNumber(value, currency)}
                    />
                    <Tooltip
                        // labelFormatter={tooltipLabelFormatter}
                        formatter={tooltipFormatter}
                    />
                    {showLegend && <Legend {...legendStyle} />}
                    {lines.map((line) => (
                        <Line
                            key={`${line.dataKey}-line`}
                            type="monotone"
                            dataKey={line.dataKey}
                            name={line.name}
                            stroke={line.color}
                            activeDot={{ r: 8 }}
                            strokeWidth={3}
                            dot={false}
                            legendType="circle"
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};
