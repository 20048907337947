// hooks/useChartLoadingStates.js
import { useState, useEffect } from 'react';

export const useChartLoadingStates = (loadingState, filteredStats, chartData, transformedData) => {
    const [states, setStates] = useState({
        isMetricsLoading: true,
        isLineChartLoading: true,
        isActiveUsersLoading: true,
        isOrganicTrafficLoading: true,
        isPageViewsLoading: true,
        isSourcesLoading: true,
        isDevicesLoading: true,
        isNewVsReturningLoading: true,
        isNewUsersLoading: true,
        isAveragePagesLoading: true,
        isSessionsPerUserLoading: true
    });

    useEffect(() => {
        if (!loadingState) {
            setStates({
                isMetricsLoading: Object.keys(filteredStats).length === 0,
                isLineChartLoading: chartData.length === 0,
                isActiveUsersLoading: !chartData?.length,
                isOrganicTrafficLoading: !transformedData.organicChartData?.length,
                isPageViewsLoading: !chartData?.length,
                isSourcesLoading: !transformedData.sourcesData?.length,
                isDevicesLoading: !transformedData.devicesData?.length,
                isNewVsReturningLoading: !transformedData.newVsReturningData?.length,
                isNewUsersLoading: !transformedData.newUsersData?.length,
                isAveragePagesLoading: !transformedData.averagePagesData?.length,
                isSessionsPerUserLoading: !transformedData.sessionsPerUserData?.length
            });
        }
    }, [loadingState, filteredStats, chartData, transformedData]);

    return states;
};
